@import '../../styles/variableColorStyles.scss';


.network-card {
  margin: 30px;
  .ant-card-head-title {
    white-space: normal;
  }
  .ant-card-actions {
    border-top: 1px solid $network;
    li {
      border-color: #d9d9d9;
    }

    .text {
      color: $network;
    }
  }
  .community-buttons{
    display: flex;
    flex-wrap: wrap;
    .button {
      font-size: 1.2em;
      font-weight: 600;
    }
  }
  .list-inline {
    li {
      display:inline;
    }
    li:not(:first-child):before {
      margin-right: 5px;
      margin-left: 5px;
      content: '\2022';
    }
  }
  ul {
    padding-left: 0;
  }
}
