.imageCollage {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 2px solid;
    border-color: white;
  }
}
.ggLogo {
  width: 200px;
  padding-bottom: 20px;
}

