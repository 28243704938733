@mixin popover ($bk-color) {
    color: white;
    max-width: 230px;
    background-color: $bk-color;
    -webkit-box-shadow: 0px 1px 3px #0d4668;
            box-shadow: 0px 1px 3px #0d4668;
    span, h4 {
      color: white;
    }
    a {
      font-family: 'helvetica neue';
      text-transform: uppercase;
      font-weight: 800;
      padding: 5px;
      background: white;
      border-radius: 2px;
      font-size: 16px;
      color: $bk-color;
      display: inline-block;
      width: 100%;
      text-align: center;
      margin-top: 10px;
    }
    .tag-container {
      margin: 2px;
      display: flex;
      justify-content: space-evenly;
    }
    .tag {
      border-radius: 3px;
      padding: 2px 10px;
      border: 1px solid white;
      margin: 1px;
    }
  }
  @mixin group-popover-icon($url) {
    height: 20px;
    width: 20px;
    margin: 5px;
    background-image: url($url);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    display: inline-block;
}
