@import "../../styles/variableColorStyles.scss";
@mixin checkbox($color) {
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: $color;
            border-color: $color;
        }

        // .ant-checkbox-checked::after {
        //     border: 
        // }
}

.filter-container {
    background: #ffffffb5;
    .ant-checkbox-wrapper {
        margin-left: 8px;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: gray;
        border-color: gray;
    }
    .general {
        @include checkbox(#8048f3)
    }
    .offer-support {
        @include checkbox(#6ac1e5)
    }
    .request-support {
        @include checkbox(#ef4822)
    }
    .food-resource {
        @include checkbox($food-resource)
    }
    .network {
        @include checkbox($network)
    }
}

.filter-container.absolute-pos {
    position: absolute;
    top: 125px;
    left: 10px;
    z-index: 2;
    width: 165px;
}

@media screen and (max-width: 639px) and (min-width: 589px) {
    .filter-container.absolute-pos {
        top: 145px;
    }
}

@media screen and (max-width: 588px) and (min-width: 398px) {
    .filter-container.absolute-pos {
        top: 175px;
    }
}

@media screen and (max-width: 397px) and (min-width: 315px) {
    .filter-container.absolute-pos {
        top: 200px;
    }
}

@media screen and (max-width: 314px) {
    .filter-container.absolute-pos {
        top: 220px;
    }
}
