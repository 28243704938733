@import './popover.scss';
@import './popovertip.scss';
@import '../../styles/variableColorStyles.scss';

.mapboxgl-popup {
    z-index: 4;
}
.popover-general {
    .mapboxgl-popup-content {
      @include popover($general);
    }
    @include popover-tip($general);
  }

.popover-network {
    .mapboxgl-popup-content {
      @include popover($network);
    }
    @include popover-tip($network);
  }

.popover-food-resource {
    .mapboxgl-popup-content {
        @include popover($general);
    }
    @include popover-tip($general);
}
