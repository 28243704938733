@import '../../styles/variableColorStyles.scss';

.form-link {
  margin-bottom: 0.5em;
  a {
    width: 160px;
  }
}

@mixin link-button($color) {
  color: $color;
  border-color: $color;
}

@mixin link-button-hover($color) {
  background-color: $color!important;
  color: #ffffff;
}
.resources {
  list-style-type:none;
  padding-left: 0;
  font-weight: bold;
  padding-left: 0;
  .offer {
    @include link-button($offer)
  }
  .offer:hover{
    @include link-button-hover($offer)
  }
  .general {
    @include link-button($general)
  }
  .general:hover {
    @include link-button-hover($general)
  }
  .request {
    @include link-button($request)
  }
  .request:hover {
    @include link-button-hover($request)
  }
  .other {
    @include link-button($info)
  }
  .other:hover {
    @include link-button-hover($info)
  }
}
