@import '../styles/variableColorStyles.scss';

.ant-layout-header {
    .logo {
        // background: rgba(255, 255, 255, 0.2);
        // margin: 16px 28px 16px 0;
        width: 145px;
        height: 100%;
        float: left;
        color: white;
        font-weight: 900;
        background-image: url('../assets/logo-transparent.png');
        background-size: contain;
      }
    background: $info;
    .ant-menu-dark.ant-menu-horizontal {
        background: $info;
        .ant-menu.item .ant-menu-item-only-child .ant-menu-item-selected {
            background: #27a4b585;
        }
    }
    .menu-btn {
        color: white;
    }
}

.ant-layout-sider {
    .logo {
        width: 145px;
        height: 50px;
        float: left;
        color: white;
        font-weight: 900;
        background-image: url('../assets/logo-transparent.png');
        background-size: contain;
    }
    background: $info;
    .ant-menu-dark {
        background: $info;
        .ant-menu-item-selected {
            background-color: #27a4b585;
        }
    }
}

.ant-layout-content {
    padding: 0 !important;
    .main-container {
        background: #fff;
        padding-bottom: 30px;
        position: relative;
        .interactive-content-default {
            display: flex;
            flex-flow: row;
            #map {
                // flex-grow: 1;
                width: 100%;
            }
            .list-container {
                display: none;
                width: 0;
            }
        }
        .interactive-content-list {
            display: flex;
            #map {
                // flex-grow: 1;
                // width: 30%
                flex: 2;
                min-height: 50vh;
            }
            .list-container {
                background: #f3f3f3;
                min-height: 70vh;
                max-height: 70vh;
                flex: 3;
                overflow: scroll;
                // width:70%;
            }
        }
        .page-container {
            padding: 50px 30px 50px 30px;
            width: 90vw;
            min-width: 300px;
            max-width: 1200px;
            margin: auto;
            a {
                color: $offer
            }
            a:hover {
                color: darken($offer, 17.5%);
            }
            p {
                padding-top: 30px;
                font-size: 1.2em;
                font-weight: normal;
            }
            .privacy-policy {
                p {
                    padding-top: 15px;
                }
                h3 {
                    padding-top: 30px;
                }
            }
        }
        .table-container {
            padding: 10px 30px;
            width: 90vw;
            min-width: 300px;
            max-width: 1200px;
            margin: auto;
        }
        .tagline {
            text-align: center;
            padding: 10px;
        }
        .title {
            font-weight: 700;
            color: $info;
            font-size: 2.8em;
        }
    }
}

@media all and (max-width: 750px) {
    .interactive-content-list {
        flex-flow: column;
        #map {
            flex: 1 100%;
            height: 30vh;
            // order: 1;
        }
        .list-container {
            max-height: none!important;
            overflow: visible!important;
            // width:70%;
        }
    }
}

.ant-layout-footer {
    bottom: 0;
    width: 100%;
    .footer-text {
        max-width: 500px;
        margin: 0 auto;
    }
}

.webGl-warning {
    max-width: 750px;
    margin: auto;
    text-align: center;
    background-color: #bb65656e;
    border-radius: 5px;
    border: 1px solid #bb6565;
}